import Lightbox from '/src/libraries/node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from '/src/libraries/node_modules/photoswipe/dist/photoswipe.esm.js';


const lightbox = new Lightbox({
  gallery: '.pswp-gallery',
  children: 'a',
  pswpModule: PhotoSwipe,
});

lightbox.init();
